// third-party
import { createSlice } from '@reduxjs/toolkit';
import { toInteger } from 'lodash';
import { openSnackbar } from 'store/slices/snackbar';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    promociones: [],
    descuentos: [],
    cupones: [],
    tipo: '',
    customersFiltrados: [],
    status: 0,
    cargandoDescuento: false,
    loading: false,
    promocionesSucursal: []
};

const slice = createSlice({
    name: 'descuento',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRMOCIONES
        getPromocionesSuccess(state, action) {
            state.promociones = action.payload;
        },

        // GET CUPONES
        getCuponesSuccess(state, action) {
            state.cupones = action.payload;
        },

        getDescuentosSuccess(state, action) {
            state.descuentos = action.payload;
        },

        setTipo(state, action) {
            state.tipo = action.payload;
        },

        // SET CATEGORIES
        setStatusSuccess(state, action) {
            state.status = action.payload;
        },

        createStatusReset(state) {
            state.status = 0;
        },

        // SET CARGANDO = TRUE
        setCargandoSuccess(state) {
            state.cargandoDescuento = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoSuccess(state) {
            state.cargandoDescuento = false;
        },

        setLoadingSuccess(state) {
            state.loading = true;
        },

        unsetLoadingSuccess(state) {
            state.loading = false;
        },

        // GET DESCUENTOS SUCURSALES
        getPromocionesSucursalesSuccess(state, action) {
            state.promocionesSucursal = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function limpiarErrores() {
    return async () => {
        try {
            dispatch(slice.actions.hasError(null));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargando() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setLoading() {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetLoading() {
    return async () => {
        try {
            dispatch(slice.actions.unsetLoadingSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPromociones(idNegocio) {
    return async () => {
        try {
            const response = await axios.get(`/getPromociones`, {
                params: {
                    id_negocio: idNegocio
                }
            });
            // console.log('promociones', response);
            dispatch(slice.actions.getPromocionesSuccess(response.data.promociones));
            dispatch(slice.actions.createStatusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// get cupones
export function getCupones(idNegocio) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getCuponesNegocio/${idNegocio}`);
            dispatch(slice.actions.getCuponesSuccess(response.data.cupones));
            dispatch(slice.actions.setTipo('cupon'));
            dispatch(unsetCargando());
        } catch (error) {
            console.log('error', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createDiscount(data) {
    return async (dispatch) => {
        dispatch(limpiarErrores());
        dispatch(setCargando());
        try {
            let response;
            if (data.tipo === 'descuento') {
                response = await axios.post(`/agregarPromocion`, data);
            }
            if (data.tipo === 'cupon') {
                response = await axios.post(`/agregarCupon`, data);
            }

            if (!response || response.code >= 400) {
                throw new Error(response.data.message || 'Error en la solicitud');
            }

            dispatch(slice.actions.setStatusSuccess(response.status));
        } catch (error) {
            const errorData = error?.data || { error: ['Ocurrió un error inesperado.'] };

            dispatch(slice.actions.hasError(errorData));
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Error al crear el ${data.tipo}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    details: errorData
                })
            );
        } finally {
            dispatch(unsetCargando());
        }
    };
}

export function updateDiscount(data) {
    return async () => {
        dispatch(limpiarErrores());
        dispatch(setCargando());
        try {
            if (data.tipo === 'descuento') {
                const response = await axios.post(`/editarPromocion`, data);
                dispatch(slice.actions.setStatusSuccess(response.data.code));
            }
            if (data.tipo === 'cupon') {
                const response = await axios.post(`/editarCupon`, data);
                dispatch(slice.actions.setStatusSuccess(response.data.code));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargando());
        }
    };
}

// editar estado opcion
export function updateStatusDiscount(data) {
    dispatch(setLoading());
    return async () => {
        try {
            if (data.tipo === 'cupon') {
                const response = await axios.post(`/updateEstadoCupon`, data);
                dispatch(slice.actions.getCuponesSuccess(response.data.cupones));
                dispatch(slice.actions.setTipo('cupon'));
            }
            if (data.tipo === 'promocion') {
                const response = await axios.post(`/updateEstadoPromocion`, data);
                dispatch(slice.actions.getDescuentosSuccess(response.data.promociones));
                dispatch(slice.actions.setTipo('promocion'));
            }
            dispatch(unsetLoading());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(unsetLoading());
        }
    };
}

// editar estado opcion
export function getPromocionesSucursal() {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getPromocionesSucursal`);
            dispatch(slice.actions.getCuponesSuccess(response.data.data.cupones));
            dispatch(slice.actions.getDescuentosSuccess(response.data.data.descuentos));
            dispatch(slice.actions.setTipo('cupon'));
            dispatch(slice.actions.createStatusReset());
        } catch (error) {
            const mensaje = error.response?.data?.message || 'Error al obtener promociones';
            dispatch(slice.actions.hasError(mensaje));
        } finally {
            dispatch(unsetCargando());
        }
    };
}

export function getPromocionesSucursales(tipo, descuentoId) {
    return async () => {
        try {
            dispatch(setLoading());
            const response = await axios.get(`/getPromocionesSucursales`, {
                params: {
                    descuento_id: descuentoId,
                    tipo
                }
            });

            dispatch(slice.actions.getPromocionesSucursalesSuccess(response.data.sucursales));
        } catch (error) {
            const mensaje = error.response?.data?.message || 'Error al obtener sucursales del producto';
            dispatch(slice.actions.hasError(mensaje));
        } finally {
            dispatch(unsetLoading());
        }
    };
}
