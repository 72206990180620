// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    popups: [],
    popupActivo: null,
    popupEditar: null,
    cargando: false,
    anuncios: [],
    categoriasOrder: [],
    productosOrder: [],
    sucursalesPopUp: []
};

const slice = createSlice({
    name: 'configuracionWeb',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET POPUPS
        getPopupsSuccess(state, action) {
            state.popups = action.payload;
        },

        // LIMPIAR POPUPS
        limpiarPopupsSuccess(state) {
            state.popups = [];
        },

        // ACTIONS POPUP
        actionsPopupSuccess(state) {
            state.error = null;
        },

        // POPUP QUE SE VA A EDITAR
        seleccionarPopupSuccess(state, action) {
            state.popupEditar = action.payload;
        },

        // LIMPIAR POPUP EDITADO
        limpiarPopupSeleccionadoSuccess(state) {
            state.popupEditar = null;
        },

        // SELECCIONAR POPUP ACTIVO
        setPopupActivoSuccess(state, action) {
            state.popupActivo = action.payload;
        },

        // SET CARGANDO = TRUE
        setCargandoSuccess(state) {
            state.cargando = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoSuccess(state) {
            state.cargando = false;
        },

        // GET CATEGORIES ORDER
        getCategoriesOrderSuccess(state, action) {
            state.categoriasOrder = action.payload;
        },

        // GET products ORDER
        getProductsOrderSuccess(state, action) {
            state.productosOrder = action.payload;
        },

        getSucursalesPopUpSuccess(state, action) {
            state.sucursalesPopUp = action.payload;
        },

        // UPDATE CATEGORIES ORDER
        setCategoriesOrderSuccess(state, action) {
            state.categoriasOrder = action.payload;
        },

        // UPDATE PRODUCTS ORDER
        setProductsOrderSuccess(state, action) {
            state.productosOrder = action.payload;
        },

        // UPDATE CATEGORIAS ORDEN EN API
        updateCategoriesOrderApiSuccess(state) {
            state.error = null;
        },

        // UPDATE PRODUCTOS ORDEN EN API
        updateProductsOrderApiSuccess(state) {
            state.error = null;
        }
    }
});

// Reducer
export default slice.reducer;

export function setCargando() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSucursalesPopUp(popUpId) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getSucursalesPopUp`, {
                params: {
                    popup_id: popUpId
                }
            });

            dispatch(slice.actions.getSucursalesPopUpSuccess(response.data.sucursales));
        } catch (error) {
            const mensaje = error.response?.data?.message || 'Error al obtener sucursales del Pop Up';
            dispatch(slice.actions.hasError(mensaje));
        } finally {
            dispatch(unsetCargando());
        }
    };
}

export function getPopups() {
    dispatch(setCargando());
    return async () => {
        try {
            const response = await axios.get('/getPopUps');

            dispatch(slice.actions.getPopupsSuccess(response.data.data));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(unsetCargando());
        }
    };
}

export function agregarPopup(info) {
    return async () => {
        try {
            await axios.post('/agregarPopUp', {
                info
            });

            dispatch(slice.actions.actionsPopupSuccess());
            dispatch(getPopups());
            dispatch(slice.actions.limpiarPopupSeleccionadoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editarPopup(info) {
    dispatch(setCargando());
    return async () => {
        try {
            await axios.post('/editarPopUp', { info });
            dispatch(slice.actions.actionsPopupSuccess());
            dispatch(getPopups());
            dispatch(slice.actions.limpiarPopupSeleccionadoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(unsetCargando());
        }
    };
}

export function updateStatePopUp(info) {
    dispatch(setCargando());
    return async () => {
        try {
            await axios.post('/updateStatePopUp', { info });
            dispatch(slice.actions.actionsPopupSuccess());
            dispatch(getPopups());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(unsetCargando());
        }
    };
}

export function deletePopUp(idPopUp) {
    dispatch(setCargando());
    return async () => {
        try {
            await axios.post('/deletePopUp', { id_pop_up: idPopUp });
            dispatch(setCargando());
            dispatch(slice.actions.actionsPopupSuccess());
            dispatch(getPopups());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(setCargando());
        }
    };
}

export function limpiarPopups() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarPopupsSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function seleccionarPopup(popup) {
    return async () => {
        try {
            dispatch(slice.actions.seleccionarPopupSuccess(popup));
            dispatch(getSucursalesPopUp(popup.id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarPopupSeleccionado() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarPopupSeleccionadoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setPopupActivo(popup) {
    return async () => {
        try {
            dispatch(slice.actions.setPopupActivoSuccess(popup));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumnsCategoriesOrder(categories) {
    return async () => {
        try {
            const categoriesId = categories.map((categoria) => categoria.id);

            const columnCategorias = [
                {
                    id: 'categorias',
                    title: 'categorias',
                    itemIds: categoriesId
                }
            ];

            dispatch(slice.actions.getCategoriesOrderSuccess(columnCategorias));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumnsProductsOrder(products) {
    return async () => {
        try {
            const productsId = products.map((producto) => producto.id);

            const columnProducts = [
                {
                    id: 'productos',
                    title: 'productos',
                    itemIds: productsId
                }
            ];

            dispatch(slice.actions.getProductsOrderSuccess(columnProducts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnCategoriesOrder(column) {
    return async () => {
        try {
            // const response = await axios.post('/api/kanban/update-item-order', { columns });
            dispatch(slice.actions.setCategoriesOrderSuccess(column));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnProductsOrder(column) {
    return async () => {
        try {
            dispatch(slice.actions.setProductsOrderSuccess(column));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCategoriesOrderApi(order) {
    return async () => {
        try {
            await axios.post('/updateCategoriasOrden', {
                categorias: order
            });

            dispatch(slice.actions.updateCategoriesOrderApiSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProductsOrderApi(order) {
    return async () => {
        try {
            await axios.post('/updateProuctosOrden', {
                productos: order
            });

            dispatch(slice.actions.updateProductsOrderApiSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
